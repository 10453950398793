import Component from '../lib/Component'
import Swiper from 'swiper'

export default class HeroSlider extends Component {
    sliderIndex:number = 0
    interval: number = 6000
    sliderList:any[] = []
    mount() {

    }
    view() {
        let commonSetting = {
            effect: 'fade',
            speed: 1500,
            loop: true
        }
        this.sliderList.push(new Swiper('#js-hero-slider1', commonSetting));
        this.sliderList.push(new Swiper('#js-hero-slider2', commonSetting));
        setInterval(() =>{this.showNextSlide();}, this.interval);
    }

    showNextSlide(){
        this.sliderList[this.sliderIndex].slideNext();
        this.setNextIndex();
    }

    setNextIndex(){
        if (this.sliderIndex == 1){
            this.sliderIndex = 0;
        }else{
            this.sliderIndex++;
        }
    }
}


