import Component from '../lib/Component'
import Swiper from 'swiper'

export default class TopVoiceSlider extends Component {
    mount() {
        new Swiper(this.element, {
            slidesPerView: 'auto',
            spaceBetween: 60,
            loop:true,
            watchOverflow:true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                900: {
                    spaceBetween: 80,
                }
            }
        });
    }

}

