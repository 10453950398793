import Component from '../lib/Component'
import * as xml2js from 'xml2js';
import axios from 'axios-https-proxy-fix';


export type ComponentList = () => Component[]

export default class Hatena extends Component {
    HATENA_URL:string = 'https://live-ap.net/hatena.php'

    mount() {
        this.getAllEntries()
    }

    getAllEntries(){

        axios.get(this.HATENA_URL).then((entries) =>{
            for (let i = 0; i < entries.data.length; i++) {
                let article = document.createElement('article')
                article.classList.add('home-Media_Item','home-MediaUnit');
                let text = '<a class="home-MediaUnit_Link" href="'+entries.data[i]['link']+'">' +
                '<div class="home-Media_Body">' +
                '<div class="home-Media_Date">'+entries.data[i]['published']+'</div>' +
                '<div class="home-Media_Title">'+entries.data[i]['title']+'</div>' +
                '</div>' +
                '</a>';
                article.innerHTML = text;
                this.element.appendChild(article);
            }
        });


    }



}

