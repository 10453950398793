import Component from '../lib/Component'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

export default class PageScroll extends Component {
    _scroll(selector: string) {
        let target:HTMLElement | string
        let scrollOffset:Number = 0
        if(selector == "#top"){
            target = document.body;
        }else{
            target = selector;
        }

        let header =  document.getElementById('js-header');
        if(header){
           scrollOffset = header.offsetHeight;
        }
        gsap.to(window,{
            duration: 0.8,
            scrollTo:{y:target, offsetY:scrollOffset,autoKill:false}
        })
    }

    mount() {
        this.on('click', e => {
            const target = this.element.getAttribute('href');
            if(target){
                this._scroll(target)
            }
            e.preventDefault()
            return true;
        })
    }
}

