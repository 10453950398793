import Component from '../lib/Component'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin)

import ScrollHint from 'scroll-hint'

export default class Body extends Component {
    mount() {
        const browser:string = this.parser.getBrowser().name;
        let target:HTMLElement | string
        document.documentElement.dataset.browser = browser;

        new ScrollHint('.js-scrollable');
    }
}

